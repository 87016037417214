/* eslint-disable @typescript-eslint/no-explicit-any */
import { Feature } from "geojson";
import { MapGeoJSONFeature } from "maplibre-gl";


export const dotNotationToNestedObject = (
	dotNotatedObj: Record<string, unknown>
): Record<string, unknown> => {
	const result: Record<string, any> = {}; // 'any' is needed here to allow dynamic nesting.

	Object.keys(dotNotatedObj).forEach(key => {
		const keys = key.split('.');
		let currentLevel = result;

		keys.forEach((part, index) => {
			if (!currentLevel[part]) {
				// If it's the last part of the key, assign the value
				currentLevel[part] = index === keys.length - 1 ? dotNotatedObj[key] : {};
			}
			// Move to the next level
			currentLevel = currentLevel[part];
		});
	});

	return result;
};

export const propertiesDotNotatedToObject = (
	properties: Feature | MapGeoJSONFeature
): Feature | MapGeoJSONFeature => {
	return {
		...JSON.parse(JSON.stringify(properties)),
		properties: {
			...properties.properties,
			...dotNotationToNestedObject(properties?.properties as Record<string, unknown>)
		}
	};
};

// Function to modify the template string to add | safeFilter before every }}
export const addLanguageFilterToTemplate = (templateString: string): string => {
	// Regex to match }} and %}, but only if | language is not already present and it's not a closing block tag
	return (
		templateString
			// Ensure there's a space after {{ if missing
			.replace(/(\{\{)(\S)/g, '$1 $2')
			// Add | language before }} only if it's not already there and there's no | safe present
			.replace(/(\{\{(?!.*\|\s*language)(?!.*\|\s*safe)[^}]+)(}})/g, '$1 | language }}')
			// Add | language before %}, ignoring cases for various Nunjucks control blocks excluding if (?!\s*if\b)
			.replace(
				/(\{%((?!.*\|\s*language)(?!\s*end\w+)(?!\s*for\b)(?!\s*set\b)(?!\s*else\b)(?!\s*block\b)(?!\s*macro\b)(?!\s*include\b)(?!\s*import\b)(?!\s*comment\b)(?!\s*switch\b)(?!\s*case\b)[^%]+))(%\})/g,
				'$1 | language %}'
			)
			// If | safe is present, add | language before it
			.replace(/(\|\s*)safe/g, '| language | safe')
	);
};
